'use strict';

import Vue from 'vue'
import store from 'client/store'
import config from "@/config";

const concat = require("lodash/concat");
const filter = require("lodash/filter");
const find = require("lodash/find");
const findIndex = require("lodash/findIndex");
const forEach = require("lodash/forEach");
const map = require("lodash/map");
const slice = require("lodash/slice");
const sortBy = require("lodash/sortBy");

import {floor} from 'mathjs'
import moment from 'moment'

import participantLib from 'client/lib/participant'
import planningLib from 'client/lib/planning'
import sessionLib from 'client/lib/session'

export default {
  title: function(block, full = true, addExerciseType = false) {
    const rotationType = sessionLib.getSessionRotationType(block.sessionId)
    const session = find(store.state.sessions.items, item => {
      return item.id === block.sessionId;
    });

    let setLabel = sessionLib.getSetLabel();
    let blockNr = block.index + 1;
    if (rotationType === 'block') {
      blockNr = session.sets * block.index + block.set;
    }

    let title = Vue.i18n.t('block') + ' ' + blockNr;
    if (addExerciseType && rotationType === 'rotation') {
      const exercise = this.getBlockExerciseType(session, block, 0);

      title += ' ' + Vue.i18n.t('exercise.type.' + exercise);
    }

    if (full && rotationType === 'block') {
      title += ' (' + Vue.i18n.t(setLabel) + ' ' + block.set + ')'
    }
    return title;
  },

  /** find which apparatus a block of gymnasts is on in specific rotation (rotation type is rotation).
   * Take into account:
   * - rest rotations
   * - exercise type select in session
   */
  getBlockExerciseType: function(session, block, rotation = 0) {
    const exerciseTypes = map(store.getters.sessionExerciseTypes(session, true), item => item.id)
    const rotations = sessionLib.getSetRotations(session, block.set)
    const restRotations = rotations - exerciseTypes.length
    const exerciseIndex = (block.index + rotation) % session.rotations

    // is it a rest rotation?
    if (restRotations) {
      const skipGap = floor(rotations / restRotations)

      for (let i=0; i < restRotations; i++) {
        const restIndex = i*skipGap + (skipGap-1)
        exerciseTypes.splice(restIndex, 0, 'rest')
      }
    }

    return exerciseTypes[exerciseIndex]
  },

  getMixedOrderPanel: function(blockParticipation, participation, session) {
    const sessionCategory = find(session.categories, sessionCategory => {
      return participation.categoryId === sessionCategory.categoryId;
    });

    if (sessionCategory) {
      const sessionCategoryExercise = find(sessionCategory.exercises, sessionCategoryExercise => {
        return blockParticipation.exerciseTypeId === sessionCategoryExercise.exerciseTypeId;
      });
      if (sessionCategoryExercise) {
        return sessionCategoryExercise.set;
      }
    }

    return false
  },

  checkMixed: function(blockParticipation, participation, session, panel) {
    if (! panel) {
      return true;
    }

    const exercisePanel = this.getMixedOrderPanel(blockParticipation, participation, session)
    return exercisePanel === panel.set
  },

  getPanelOrder: function(panel, rotation) {
    const session = find(store.state.sessions.items, item => item.id === panel.sessionId)
    const blocks = filter(store.state.blocks.items, item => item.sessionId === session.id)

    const rotations = sessionLib.getSetRotations(session, panel.set)

    if (rotation >= rotations) {
      return {}
    }

    const rotationType = sessionLib.getSessionRotationType(panel.sessionId)

    const settings = find(store.state.panels.configurations, item => item.panelId === panel.id)

    return map(settings.passes, pass => {
      let passSet = pass.set
      let exerciseTypeId = pass.exerciseTypeId
      let blockIndex = store.getters.panelRotationBlockIndex(session, panel, rotation)

      const block = find(blocks, item => item.set === passSet && item.index === blockIndex)

      const blockParticipations = filter(this.getBlockParticipations(block.id), item => {
        const part = participantLib.getParticipation(item.participationId)
        if (! part) return false

        if (rotationType === 'mixed') {
          return this.checkMixed(item, part, session, panel)
        }
        return true
      })
      const blockParticipationsSorted = this.orderBlockParticipations(blockParticipations, block, session, rotation)

      return {
        exerciseTypeId: exerciseTypeId,
        block: block,
        participations: blockParticipationsSorted,
      }
    })
  },

  getBlockParticipations: function(blockId) {
    let blockParticipations = filter(store.state.blockParticipations.items, item => item.blockId === blockId)
    return sortBy(blockParticipations, 'index')
  },

  getBlockPartsPerRotation: function(block, rotation) {
    const blockRotation = find(block.rotationOrders, item => {
      return item.rotation === rotation;
    });

    if (! blockRotation) {
      return [];
    }

    return map(blockRotation.participations, participationId => {
      return {
        participationId: participationId,
      }
    });
  },

  orderBlockParticipations: function(blockParticipations, block, session, activeRotation) {
    let blockParticipationsSorted = sortBy(blockParticipations, 'index')

    // shift participations when rotationType is alternating
    if (session.rotationType === 'alternating' && blockParticipationsSorted.length > 1) {
      let shift = activeRotation
      if (shift > 0) {
        // check if a rest rotation exists
        const exercises = store.getters.sessionExerciseTypes(session, true)
        if (exercises.length < session.rotations) {
          if (block.index >= session.rotations - activeRotation) {
            shift -= 1
          }
        }

        const blockParticipationsPresent = filter(blockParticipationsSorted, item => {
          const participation = participantLib.getParticipation(item.participationId)
          const partRound = find(participation.rounds, round => round.roundId === session.roundId)
          if (! partRound) {
            return false
          }
          return partRound.status === 'present'
        });

        shift %= blockParticipationsPresent.length;
        if (shift > 0) {
          const firstId = blockParticipationsPresent[shift].id
          const shiftIndex = findIndex(blockParticipationsSorted, item => item.id === firstId)
          const partTwo = slice(blockParticipationsSorted, 0, shiftIndex)
          const partOne = slice(blockParticipationsSorted, shiftIndex, blockParticipationsSorted.length)
          blockParticipationsSorted = concat(partOne, partTwo)
        }
      }
    } else if (session.rotationType === 'schedule') {
      const exerciseTypeId = this.getBlockExerciseType(session, block, activeRotation)
      const order = block.exerciseOrders?.[exerciseTypeId]
      // const rotationOrder = find(block.rotationOrders, item => item.rotation === activeRotation)

      if (order) {
        blockParticipationsSorted = map(order, participationId =>
          find(blockParticipations, blockParticipation => blockParticipation.participationId === participationId))
      }
    }

    return blockParticipationsSorted;
  },

  getBlockParticipationExerciseType: function (blockParticipation, passExerciseTypeId) {
    return blockParticipation.exerciseTypeId ? blockParticipation.exerciseTypeId : passExerciseTypeId
  },

  assembleBlockItem: function(config, blockParticipation, index, session, discipline, context, exerciseTypeId = null) {
    const data = {}
    if (blockParticipation.filler) {
      data.filler = 'filler'
      data.panel = context.previous.panel
      data.exerciseTime = context.previous.exerciseTime
    } else {
      const participation = participantLib.getParticipation(blockParticipation.participationId)
      const teamParticipation = participantLib.getTeamParticipation(participation)
      const participant = participantLib.getParticipant(participation)
      const category = participantLib.getCategory(participation);
      const exerciseType = exerciseTypeId ? exerciseTypeId : blockParticipation.exerciseTypeId
      let exerciseName = exerciseTypeId ? exerciseTypeId : blockParticipation.exerciseTypeId ?
        participantLib.getExerciseLabel(participation, session.roundId, blockParticipation.exerciseTypeId) : null
      const panel = blockParticipation.exerciseTypeId ?
        this.deriveSet(session, category.id, blockParticipation.exerciseTypeId) : null
      const exerciseTime =
        planningLib.getExerciseTime(config, session.roundId, category.id, exerciseType)

      data.participantId = participant.id
      data.bib = (participation.bib ? participation.bib : '') + (teamParticipation?.bib ? '/' + teamParticipation.bib : '')
      data.clubName = participantLib.getClubName(participation, participant, context.addCountry)
      data.categoryName = category.name
      data.name = participantLib.getName(participation)
      data.names = participantLib.getParticipantNames(participation)
      data.group = category.participantType === 'group'
      data.panel = panel
      data.exerciseType = exerciseType
      data.exerciseName = exerciseName
      data.exerciseTime = exerciseTime
      data.absent = participantLib.getStatus(participation, session.roundId) !== 'present'
    }

    if (context.time) {
      if (index === 0 ? context.addExerciseTimeGap : index < context.addExerciseTime) {
        context.time.add(data.exerciseTime, 's')
      } else if (context.time && context.previous.panel !== 0 && context.previous.panel === data.panel) {
        context.time.add(config.judgeTime, 's')
      }

      data.zones = context.zoneTimes.map(t => moment(context.time).add(-t, 'm').format('HH:mm'))

      data.timeRaw =  context.time?.isValid() ? moment(context.time) : null
      data.time = context.time?.isValid() ? context.time.format('HH:mm') : ''

      context.time.add(data.exerciseTime, 's')
    }

    return data;
  },

  deriveSet: function(session, categoryId, exerciseTypeId) {
    const sCategory = find(session.categories, cat => {
      return cat.categoryId === categoryId;
    });

    if (sCategory) {
      const sCatExerciseType = find(sCategory.exercises, item => {
        return item.exerciseTypeId === exerciseTypeId;
      });
      if (sCatExerciseType) {
        return sCatExerciseType.set;
      }
    }
    return null;
  },

  prepareBlockMixedTiming: function(block, session, context) {
    const rotationType = sessionLib.getSessionRotationType(block.sessionId)
    const secondaryType = sessionLib.getSessionRotationTypeSecondary(session)

    if (rotationType !== 'block' || secondaryType !== 'mixed' || session.sets !== 2) {
      context.addExerciseTime = false
      return
    }

    const otherBlock = this.findSessionBlock(session, block.set === 1 ? 2 : 1, block.index)

    const blockParts = this.getBlockParticipations(block.id)
    const otherBlockParts = this.getBlockParticipations(otherBlock.id)

    console.log('block lengths', blockParts.length, otherBlockParts.length)

    if (blockParts.length === otherBlockParts.length) {
      console.log('equal block length')
      context.addExerciseTimeGap = (context.set > 0)
      context.addExerciseTime = blockParts.length
      console.log('equal block length', context.set, context.addExerciseTimeGap)
    } else if (blockParts.length >= otherBlockParts.length) {
      console.log('I\'m bigger')
      context.addExerciseTime = otherBlockParts.length
    } else {
      console.log('I\'m smaller')
      context.addExerciseTimeGap = true
      context.addExerciseTime = blockParts.length
    }
  },

  assembleBlock: function(block, startTime, session, discipline, rotation = -1, set, blockIndex, forExport = false, exerciseTypeId = null) {
    const exercise = this.getBlockExerciseType(session, block, rotation >= 0 ? rotation : 0)
    if (session.rotationType === 'schedule' && exercise === 'rest') {
      return null
    }

    const eventDiscipline = store.state.eventDisciplines.items.find(e => e.id === session.eventDisciplineId)
    const config = planningLib.getPlanningConfig(session.eventDisciplineId)
    const rotationType = sessionLib.getSessionRotationType(block.sessionId)
    const mixed = rotationType === 'mixed'
    const timed = mixed || !! discipline.config.orderTiming
    const groupCategories = ! forExport && ! mixed && ! timed;

    let zoneTimes = []
    if (rotationType === 'mixed') {
      const warmup = config.warmupConfigs.find(w => w.name === session.warmup)
      if (warmup?.type === 'scheduled') {
        let time = 0
        zoneTimes = warmup.zones.slice().reverse().map(z => time += z.time).reverse()
      }
    }

    let blockParts = this.getBlockParticipations(block.id)
    if (rotation >= 0) {
      blockParts = this.orderBlockParticipations(blockParts, block, session, rotation)
    }

    const context = {
      time: startTime,
      previous: {},
      addExerciseTime: false,
      set,
      zoneTimes,
      addCountry: eventDiscipline.clubSymbols === 'flags'
    }
    this.prepareBlockMixedTiming(block, session, context)

    let items = map(blockParts, (blockPart, index) => {
      const blockItem = this.assembleBlockItem(config, blockPart, index, session, discipline, context, exerciseTypeId)
      context.previous = blockItem
      return blockItem
    });

    if (groupCategories) {
      let cat = '';
      let groupedItems = [];
      forEach(items, item => {
        if (item.categoryName !== cat) {
          groupedItems.push({
            category: item.categoryName,
          });
          cat = item.categoryName;
        }
        groupedItems.push(item);
      });
      items = groupedItems;
    }

    let title = (rotation < 0) ? this.title(block) : Vue.i18n.t('block')  + ' ' + (blockIndex+1)

    if (rotationType === 'rotation') {
      title += ' ' + Vue.i18n.t('exercise.type.' + exercise);
    }

    return {
      title,
      block: block.index+1,
      items,
    };
  },

  getRotationStartTime(session, rotation) {
    let time = null

    if (session.timeTable) {
      const key = 'rotation-start-' + rotation;
      let item = session.timeTable.find(i => i.key === key)

      if (item) {
        time = item.time
      } else {
        item = session.timeTable.find(i => i.key === 'competition')
        if (item && rotation === 0) {
          time = item.time
        }
      }
    }
    return time ? moment(time, 'HH:mm') : null
  },

  setIterationStartTime(session, rotation, iteration, iterationStartTime) {
    const key = 'rotation-' + rotation + '-exercise-' + iteration
    const item = session.timeTable?.find(i => i.key === key)

    if (item) {
      iterationStartTime = moment(item.time, 'HH:mm')
    }
    return iterationStartTime
  },

  prepareSections(discipline, session, blocks, set = 0, forExport, panels = null, panelConfigs = null) {
    const rotationType = sessionLib.getSessionRotationTypeFull(session)
    console.log('prepare sections', rotationType)
    let sections = [];

    let title = moment(session.date).format("dddd DD-MM-YYYY") + ' ' + session.name + ' ' + Vue.i18n.t('session.order')
    if (set) {
      title += ' - ' + Vue.i18n.t('set') + ' ' + set;
    }

    const timed = discipline.config.orderTiming && rotationType !== 'mixed'

    let zones = []
    if (rotationType === 'mixed') {
      const config = planningLib.getPlanningConfig(session.eventDisciplineId)
      const warmup = config.warmupConfigs.find(w => w.name === session.warmup)
      if (warmup?.type === 'scheduled') {
        zones = warmup.zones.map(z => z.label)
      }
    }

    if (session.rotationType === 'schedule') {
      for (let r = 0; r < session.rotations; r++) {
        const rotationStartTime = this.getRotationStartTime(session, r)
        let blockData = [];
        for (let b = 0; b < session.rotations; b++) {
          const blockIndex = (session.rotations + b - r) % session.rotations;
          const block = find(blocks, item => {
            return item.index === blockIndex;
          });
          const blockTmp = this.assembleBlock(block, rotationStartTime, session, discipline, r, set, b, forExport)
          if (blockTmp) {
            blockData.push(blockTmp)
          }
        }

        sections.push({
          title: title + ' ' + Vue.i18n.t('rotation') + ' ' + (r+1),
          session: session.name,
          type: 'workorder-blocks-compact',
          data: {
            blocks: blockData,
            timing: sessionLib.getSessionTimes(session),
            notes: session.description,
            zones,
          },
        });
        if (! forExport) {
          sections.push({
            type: 'pagebreak',
          });
        }
      }
    }
    else {
      let blockData = []

      if (timed) {
        console.log('timed session order')
        const exercises = sessionLib.getSessionEffectiveExerciseTypes(session)

        // map sets to panel configurations
        panelConfigs = Array.from(Array(session.sets), (e, i) => {
          const set = i+1
          const panel = panels.find(p => p.sessionId === session.id && p.set === set)
          return panelConfigs.find(c => c.panelId === panel.id)
        })

        for (let r = 0; r < session.rotations; r++) {
          let iterationStartTime = this.getRotationStartTime(session, r)
          for(let iteration = 0; iteration < exercises.length; iteration++) {
            iterationStartTime = this.setIterationStartTime(session, r, iteration, iterationStartTime)
            let setStartTime = moment(iterationStartTime)
            for (let set = 0; set < session.sets; set++) {
              console.log(set, iteration)
              const pass = panelConfigs[set].passes[iteration]
              console.log(pass)
              const block = blocks.find(b => b.index === r && b.set === pass.set)
              let blockStartTime = moment(setStartTime)
              const result = this.assembleBlock(
                block, blockStartTime, session, discipline, -1, set, 0, false, pass.exerciseTypeId)
              if (blockStartTime?.isAfter(iterationStartTime)) {
                iterationStartTime = moment(blockStartTime)
              }
              result.title += ' ' + Vue.i18n.t('exercise.type.' + pass.exerciseTypeId)
              blockData.push(result)
            }
            if (session.sets % 2 && session.sets > 1) {
              blockData.push({})
            }
          }
        }
      }
      else {
        for (let r = 0; r < session.rotations; r++) {
          const rBlocks = blocks.filter(b => b.index === r)
          blockData = concat(blockData, map(rBlocks, block => {
            const rotationStartTime = this.getRotationStartTime(session, block.index)
            return this.assembleBlock(block, rotationStartTime, session, discipline);
          }));
          if (set === 0 && session.sets % 2 && session.sets > 1) {
            blockData.push({})
          }
        }
      }

      if (rotationType === 'mixed') {
        sections.push({
          title,
          session: session.name,
          type: 'workorder-mixed',
          data: {
            blocks: [],
            timing: sessionLib.getSessionTimes(session),
            notes: session.description,
            zones,
          },
        })

        const config = planningLib.getPlanningConfig(session.eventDisciplineId)

        sections = sections.concat(map(blockData, (block, index) => {
          return {
            title: (blockData.length > 1 ? Vue.i18n.t('block') + ' ' + (index+1) : null),
            session: session.name,
            type: 'workorder-mixed',
            data: {
              blocks: [block],
              zones,
              showExerciseTime: ! config.mixedHideExerciseTime,
            },
          }
        }))
      } else {
        let workorder = 'workorder-blocks-compact'
        if (discipline.config.workorder) {
          workorder = discipline.config.workorder
        }

        sections.push({
          title,
          session: session.name,
          type: timed ? 'workorder-blocks-timed' : workorder,
          data: {
            blocks: blockData,
            timing: sessionLib.getSessionTimes(session),
            notes: session.description,
            zones,
          },
        });
      }
    }

    return sections;
  },

  // returns the report section for printing the order of a session
  preparePrint: function(session, forExport = false) {
    return new Promise((resolve, reject) => {
      const discipline = store.state.eventDiscipline.discipline
      const timed = discipline.config.orderTiming

      if (timed) {
        Vue.http.get(config.root + '/sessions/' + session.id + '/loadPanels').then((result) => {
          const sections = this.preparePrintAux(discipline, session, forExport,
            result.data.panels, result.data.configurations)
          resolve(sections)
        }, err => reject(err))
      } else {
        const sections = this.preparePrintAux(discipline, session, forExport)
        resolve(sections)
      }
    })
  },

  preparePrintAux(discipline, session, forExport, panels = null, panelConfigs = null) {
    const rotationType = sessionLib.getSessionRotationType(session.id)
    let sections = []

    const mixSets = rotationType !== 'rotation'
    const sets = mixSets ? 1 : session.sets

    for (let i = 1; i <= sets; i++) {
      let blocks = this.getSessionBlocks(session, rotationType, i, mixSets)
      const set = sets === 1 ? 0 : i
      sections = concat(sections, this.prepareSections(discipline, session, blocks, set, forExport, panels, panelConfigs))
    }

    if (! forExport) {
      if (rotationType === 'mixed') {
        sections.push({
          type: 'pagebreak',
        })
      } else {
        const sections2 = []
        forEach(sections, section => {
          sections2.push(section)
          sections2.push({
            type: 'pagebreak',
          })
        })
        sections = sections2
      }
    }

    return sections
  },

  getSessionBlocks: function(session, rotationType, set, mixSets = false) {
    const sort = rotationType === 'block' ? ['index', 'set'] : ['set', 'index']
    return sortBy(
      filter(store.state.blocks.items, item => item.sessionId === session.id && (mixSets || item.set === set)), sort)
  },
  findSessionBlock: function(session, set, rotation) {
    return find(store.state.blocks.items, item => item.sessionId === session.id && item.set === set && item.index === rotation)
  },
};
